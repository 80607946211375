
import { defineComponent, reactive, onMounted } from "vue";
import { detailGoods, approveGoods } from "../../../../../http/api/store";

interface DataGoodsDetail {
  //商品详情
  goods: any;
  //审批dialog
  approveDialog: boolean;
  approveReq: {
    goodsId: string;
    boolPass: boolean;
    reason: string;
  };
  //审批
  handleApprove: () => void;
  //取消审批
  closeApproveDialog: () => void;
  //提交审批结果
  submitApprove: () => void;
}

export default defineComponent({
  props: ["goodsId"],
  setup(props) {
    onMounted(() => {
      let goodsId = props.goodsId;
      detailGoods({ id: goodsId }).then((res) => {
        if (res.data.data !== null) {
          data.goods = res.data.data;
        }
      });
    });
    const data: DataGoodsDetail = reactive({
      //商品详情
      goods: {},
      //审批
      approveDialog: false,
      approveReq: {
        goodsId: "",
        boolPass: true,
        reason: "",
      },
      //审批
      handleApprove() {
        data.approveDialog = true;
      },
      //取消审批
      closeApproveDialog() {
        data.approveDialog = false;
        //关闭
        data.approveReq = {
          goodsId: "",
          boolPass: true,
          reason: "",
        }
      },
      //提交审批
      submitApprove() {
        let req = data.approveReq;
        req.goodsId = data.goods.id;
        approveGoods(req).then((res) => {
          if (res.data.data) {
            detailGoods({ id: req.goodsId }).then((result) => {
              if (result.data.data !== null) {
                data.goods = result.data.data;
              }
            });
            //关闭
            (data.approveDialog = false),
              (data.approveReq = {
                goodsId: "",
                boolPass: true,
                reason: "",
              })
          }
        });
      },
    });
    return { data };
  },
});
