import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58c1f3ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home_main" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }
const _hoisted_8 = { key: 6 }
const _hoisted_9 = { key: 7 }
const _hoisted_10 = { key: 8 }
const _hoisted_11 = { key: 9 }
const _hoisted_12 = { key: 10 }
const _hoisted_13 = { key: 11 }
const _hoisted_14 = { key: 12 }
const _hoisted_15 = { key: 13 }
const _hoisted_16 = { key: 14 }
const _hoisted_17 = { key: 15 }
const _hoisted_18 = { key: 16 }
const _hoisted_19 = { key: 17 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_data = _resolveComponent("main-data")!
  const _component_main_category = _resolveComponent("main-category")!
  const _component_main_template = _resolveComponent("main-template")!
  const _component_main_content = _resolveComponent("main-content")!
  const _component_main_tag = _resolveComponent("main-tag")!
  const _component_main_community = _resolveComponent("main-community")!
  const _component_main_video = _resolveComponent("main-video")!
  const _component_main_store = _resolveComponent("main-store")!
  const _component_main_goods = _resolveComponent("main-goods")!
  const _component_main_group = _resolveComponent("main-group")!
  const _component_main_store_category = _resolveComponent("main-store-category")!
  const _component_main_system = _resolveComponent("main-system")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ('1-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_main_data)
        ]))
      : _createCommentVNode("", true),
    ('2-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_main_category)
        ]))
      : _createCommentVNode("", true),
    ('2-2' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_main_template)
        ]))
      : _createCommentVNode("", true),
    ('2-3' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_main_content)
        ]))
      : _createCommentVNode("", true),
    ('2-4' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_main_tag)
        ]))
      : _createCommentVNode("", true),
    ('3-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_main_community)
        ]))
      : _createCommentVNode("", true),
    ('4-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_main_video)
        ]))
      : _createCommentVNode("", true),
    ('5-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_main_store)
        ]))
      : _createCommentVNode("", true),
    ('5-2' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_main_goods)
        ]))
      : _createCommentVNode("", true),
    ('5-3' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_main_group)
        ]))
      : _createCommentVNode("", true),
    ('5-4' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_main_store_category)
        ]))
      : _createCommentVNode("", true),
    ('6-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13))
      : _createCommentVNode("", true),
    ('6-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14))
      : _createCommentVNode("", true),
    ('7-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15))
      : _createCommentVNode("", true),
    ('7-2' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16))
      : _createCommentVNode("", true),
    ('7-3' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17))
      : _createCommentVNode("", true),
    ('7-4' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18))
      : _createCommentVNode("", true),
    ('8-1' == _ctx.props.navIndex)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(_component_main_system)
        ]))
      : _createCommentVNode("", true)
  ]))
}