import request from "../http";

/**
 * 获取当前登录人的信息
 */
export const getMy = () =>{
    return request({
        url: '/by-user/tenant/admin/getMy',
        method: 'post',
        data: null
    })
}