
import { defineComponent, reactive } from 'vue';
import CategoryTable from "./category-table.vue";
import CategoryDetail from "./category-detail.vue";
import CategoryAdd from "./category-add.vue"

interface DataMainCategory {
    //当前所处页面的类型
    pageType : string;
    //选中的类目id
    categoryId : "";
    //新增类目
    addStoreCategory:() => void
    //选中
    categoryDetailId:(id:any) => void;
    //前往主页
    toMain:() => void
}

export default defineComponent({
    components:{CategoryTable, CategoryDetail, CategoryAdd},
    setup() {
        const data:DataMainCategory = reactive({
            //当前展示的页面类型
            pageType: "list",
            //选中的类目id
            categoryId: "",
            //新增类目
            addStoreCategory(){
                data.pageType = 'add'
            },
            //店铺详情
            categoryDetailId(id:any){
                data.pageType = 'detail'
                data.categoryId = id
            },
            //前往主页
            toMain(){
                data.pageType= 'list'
                data.categoryId = ''
            }
        })
        return {data}
    },
})
