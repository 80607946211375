
import { defineComponent, reactive, onMounted } from "vue";
import {
  detailGroup,
  passGroup,
  rejectGroup,
} from "../../../../../http/api/store";

interface DataGroupDetail {
  //团购
  group: any;
  //审批dialog
  approveDialog: boolean;
  approveReq: {
    groupId: string;
    boolPass: boolean;
    reason: string;
  };
  //审批
  handleApprove: () => void;
  //取消审批
  closeApproveDialog: () => void;
  //提交审批结果
  submitApprove: () => void;
}
export default defineComponent({
  props: ["groupId"],
  setup(props, { emit }) {
    onMounted(() => {
      let groupId = props.groupId;
      detailGroup({ id: groupId }).then((res) => {
        if (res.data.data !== null) {
          data.group = res.data.data;
        }
      });
    });
    const data: DataGroupDetail = reactive({
      //团购
      group: {},
      //审批
      approveDialog: false,
      approveReq: {
        groupId: "",
        boolPass: true,
        reason: "",
      },
      //审批
      handleApprove() {
        data.approveDialog = true;
      },
      //取消审批
      closeApproveDialog() {
        data.approveDialog = false;
        //关闭
        data.approveReq = {
          groupId: "",
          boolPass: true,
          reason: "",
        };
      },
      //提交
      submitApprove() {
        let req = data.approveReq;
        req.groupId = data.group.id;
        if (req.boolPass) {
          passGroup(req).then((res) => {
            if (res.data !== null) {
              detailGroup({ id: data.group.id }).then((result) => {
                if (result.data.data !== null) {
                  data.group = result.data.data;
                }
              });
              //关闭
              (data.approveDialog = false),
                (data.approveReq = {
                  groupId: "",
                  boolPass: true,
                  reason: "",
                });
            }
          });
        } else {
          rejectGroup(req).then((res) => {
            if (res.data !== null) {
              detailGroup({ id: data.group.id }).then((result) => {
                if (result.data.data !== null) {
                  data.group = result.data.data;
                }
              });
              //关闭
              (data.approveDialog = false),
                (data.approveReq = {
                  groupId: "",
                  boolPass: true,
                  reason: "",
                });
            }
          });
        }
      },
    });
    return { data };
  },
});
