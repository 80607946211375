
import { defineComponent, reactive, onMounted } from "vue";
import {
  getAllStoreBusinessType,
  getAllStoreBusiness,
  addStoreBusinessCategory,
} from "../../../../../http/api/store";
import { ElNotification } from "element-plus";
import { add } from "@/http/api/category";

interface DataCategoryAdd {
  //所有业务类型
  allBusinessType: Array<any>;
  //所有业务
  allBusiness: Array<any>;
  //新增参数
  addReq: any;
  //查询业务
  selectBusiness: () => void;
  //提交
  submit: () => void;
}

export default defineComponent({
  emits: ["toMain"],
  setup(props, { emit }) {
    onMounted(() => {
      //查询所有业务类型
      getAllStoreBusinessType().then((res) => {
        if (res.data.data !== null) {
          data.allBusinessType = res.data.data;
        }
      });
    });
    const data: DataCategoryAdd = reactive({
      //所有业务类型
      allBusinessType: [],
      //所有业务
      allBusiness: [],
      //新增参数
      addReq: {},
      //查询业务
      selectBusiness() {
        let req = {
          businessType: data.addReq.businessType,
        };
        getAllStoreBusiness(req).then((res) => {
          if (res.data.data !== null) {
            data.allBusiness = res.data.data;
          }
        });
      },
      //提交
      submit() {
        let addReq = data.addReq;
        addStoreBusinessCategory(addReq).then((res) => {
          if (res.data.data !== null) {
            emit("toMain");
          }
        });
      },
    });
    return { data };
  },
});
