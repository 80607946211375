
import { defineComponent, reactive, onMounted } from 'vue';
import StoreTable from './store-table.vue';
import StoreDetail from './store-detail.vue'

interface DataMainStore {
    //当前所处页面的类型
    pageType : string;
    //跳转的店铺详情
    storeId:string
    //店铺详情
    storeDetailId:(id:any) => void;
    //前往主页
    toMain:() => void
}

export default defineComponent({
    components:{StoreTable, StoreDetail},
    setup(props, {emit}) {
        onMounted(() => {
            
        })
        const data : DataMainStore = reactive({
            pageType: "list",
            //跳转的店铺id
            storeId: "",
            //店铺详情
            storeDetailId(id:any){
                data.pageType = 'detail'
                data.storeId = id
            },
            //前往主页
            toMain(){
                data.pageType= 'list'
                data.storeId = ''
            }
        })
        return {data} 
    },
})
