
import { defineComponent, onMounted, reactive } from "vue";
import {
  pageCommunity,
  getAllStatus,
  approveCommunity,
} from "../../../../http/api/content";

interface DataCommunityTable {
  //查询条件
  req: {
    //页数
    pageNum: number;
    //大小
    pageSize: number;
    //内容类型
    contentType: string;
    //名字
    title: string;
    //创建人名字
    creatorName: string;
    //创建时间
    startTime: "";
    //结束时间
    endTime: "";
    //状态
    status: any;
  };
  statusList: Array<Object>;
  //数据量
  count: number;
  //社区列表
  communityList: Array<Object>;
  //审核dialog
  approveDialog: boolean;
  //正在审批的社区内容
  approveCommunity: any;
  //审批参数
  approveReq: {
    id: string;
    status: number;
    reason: string;
  };
  //查询社区数据
  submit: () => void;
  //分页查询社区数据
  selectCommunity: (val: number) => void;
  //跳转详情
  handleDetail: (community: any) => void;
  //审核
  handleApprove: (community: any) => void;
  //取消审批
  closeApproveDialog: () => void;
  //提交审批结果
  submitApprove: () => void;
}

export default defineComponent({
  emits: ["detailCommunityId"],
  setup(props, { emit }) {
    //加载数据
    onMounted(() => {
      //查询社区数据
      let req = {
        pageNum: 1,
        pageSize: 10,
      };
      pageCommunity(req).then((res) => {
        if (res.data.data !== null) {
          data.communityList = res.data.data.list;
          data.count = Number(res.data.data.total);
        }
      });
      //获取所有状态信息
      getAllStatus().then((res) => {
        data.statusList = res.data.data;
        data.statusList.unshift({
          status: -1,
          name: "全部",
        });
      });
    });
    const data: DataCommunityTable = reactive({
      //查询条件
      req: {
        pageNum: 1,
        pageSize: 10,
        contentType: "",
        title: "",
        creatorName: "",
        startTime: "",
        endTime: "",
        status: null,
      },
      //状态列表
      statusList: [],
      //数据量
      count: 0,
      //社区列表
      communityList: [],
      //审批dialog
      approveDialog: false,
      //正在审批的社区信息
      approveCommunity: {},
      //审批参数
      approveReq: {
        id: "",
        status: 3,
        reason: "",
      },
      //查询社区数据
      submit() {
        let req = data.req;
        pageCommunity(req).then((res) => {
          if (res.data.data !== null) {
            data.communityList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        });
      },
      //分页查询社区数据
      selectCommunity(val: number) {
        let req = data.req;
        req.pageNum = val;
        pageCommunity(req).then((res) => {
          if (res.data.data !== null) {
            data.communityList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        });
      },
      //跳转详情
      handleDetail(community: any) {
        emit("detailCommunityId", community.id);
      },
      //审核
      handleApprove(community: any) {
        (data.approveCommunity = community), (data.approveDialog = true);
      },
      //取消审批
      closeApproveDialog() {
        (data.approveDialog = false), (data.approveCommunity = {});
        data.approveReq = {
          id: "",
          status: 3,
          reason: "",
        };
      },
      submitApprove() {
        let req = data.approveReq;
        req.id = data.approveCommunity.id;
        approveCommunity(req).then((res) => {
          if (res.data !== null) {
            (data.approveDialog = false), (data.approveCommunity = {});
            data.approveReq = {
              id: "",
              status: 3,
              reason: "",
            };
            //重新查询数据
            let req = {
              pageNum: 1,
              pageSize: 10,
            };
            pageCommunity(req).then((res) => {
              if (res.data.data !== null) {
                data.communityList = res.data.data.list;
                data.count = Number(res.data.data.total);
              }
            });
          }
        });
      },
    });
    return { data };
  },
});
