
import { Plus } from "@element-plus/icons";
import { defineComponent, reactive } from "vue";
import CommunityTable from "./community-table.vue";
import CommunityDetail from "./community-detail.vue";

interface DataContent {
  //选中要操作的内容的id
  communityId: string;
  //当前所处页面的类型
  pageType: string;
  //返回首页
  toMain: () => void;
  //获取用户详情
  detailCommunityId: (communityId: any) => void;
}
export default defineComponent({
  components: { CommunityTable, CommunityDetail, Plus },
  setup() {
    const data: DataContent = reactive({
      communityId: "",
      pageType: "list",
      toMain: () => {
        (data.pageType = "list")
      },
      detailCommunityId: (communityId: any) => {
        data.communityId = communityId;
        data.pageType = "detail";
      },
    });
    return { data };
  },
});
