
import { defineComponent, reactive } from "vue";
import GroupTable from "./group-table.vue";
import GroupDetail from "./group-detail.vue";

interface DataMainGroup {
  //页面类型
  pageType: string;
  //详情
  groupId: string;
  //跳转的详情团购
  groupDetailId: (groupId: any) => void;
  //前往主页
  toMain: () => void;
}
export default defineComponent({
  components: { GroupTable, GroupDetail },
  setup() {
    const data: DataMainGroup = reactive({
      //页面类型
      pageType: "list",
      //详情
      groupId: "",
      //跳转的详情团购
      groupDetailId(groupId: any) {
        data.pageType = "detail";
        data.groupId = groupId;
      },
      //前往列表
      toMain() {
        (data.pageType = "list"), (data.groupId = "");
      },
    });
    return { data };
  },
});
