
import { defineComponent, reactive, onMounted } from "vue";
import {
  getAllStoreBusinessType,
  getAllStoreBusiness,
  pageStoreBusinessCategory,
  useStoreBusinessCategory,
  stopStoreBusinessCategory,
} from "../../../../../http/api/store";

interface DataCategoryTable {
  //请求参数
  req: {
    status: any;
    businessType: any;
    pageSize: number;
    pageNum: number;
    business: any;
    categoryName: any;
  };
  //类目数量
  count: number;
  //所有的业务类型
  allBusinessType: Array<any>;
  //所有的业务
  allBusiness: Array<any>;
  //类目数据
  categoryList: Array<any>;
  //查询业务
  selectBusiness: () => void;
  //前往详情
  handleDetail: (item: any) => void;
  //停用
  stop: (item: any) => void;
  //启用
  use: (item: any) => void;
  //查询店铺信息
  selectCategory: (val: number) => void;
}

export default defineComponent({
  emits: ["categoryDetailId"],
  setup(props, { emit }) {
    onMounted(() => {
      getAllStoreBusinessType().then((res) => {
        if (res.data.data !== null) {
          data.allBusinessType = res.data.data;
        }
      });
      //查询类目数据
      let req = data.req;
      pageStoreBusinessCategory(req).then((res) => {
        if (res.data.data !== null) {
          data.categoryList = res.data.data.list;
          data.count = Number(res.data.data.total);
        }
      });
    });
    const data: DataCategoryTable = reactive({
      //请求参数
      req: {
        status: null,
        businessType: null,
        pageNum: 1,
        pageSize: 10,
        business: null,
        categoryName: null,
      },
      //数量
      count: 0,
      //所有的业务类型
      allBusinessType: [],
      //所有业务
      allBusiness: [],
      //类目数据
      categoryList: [],
      //查询业务
      selectBusiness() {
        let req = {
          businessType: data.req.businessType,
        };
        getAllStoreBusiness(req).then((res) => {
          if (res.data.data !== null) {
            data.allBusiness = res.data.data;
          }
        });
      },
      //前往详情
      handleDetail(item: any) {
        emit("categoryDetailId", item.id);
      },
      //停用
      stop(item: any) {
        stopStoreBusinessCategory({ id: item.id }).then((res) => {
          if (res.data.data !== null) {
            let req = data.req;
            pageStoreBusinessCategory(req).then((res) => {
              if (res.data.data !== null) {
                data.categoryList = res.data.data.list;
                data.count = Number(res.data.data.total);
              }
            });
          }
        });
      },
      //启用
      use(item: any) {
        useStoreBusinessCategory({ id: item.id }).then((res) => {
          if (res.data.data !== null) {
            let req = data.req;
            pageStoreBusinessCategory(req).then((res) => {
              if (res.data.data !== null) {
                data.categoryList = res.data.data.list;
                data.count = Number(res.data.data.total);
              }
            });
          }
        });
      },
      //分页查询
      selectCategory(val: number){
        let req = data.req
        req.pageNum = val
        //赋值
        data.req = req
        pageStoreBusinessCategory(req).then((res) => {
          if (res.data.data !== null) {
            data.categoryList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        })
      },
    });
    return { data };
  },
});
