import request from "../http";


/**
 * 分页
 */
export const pageStore = (req:any) =>{
    return request({
        url: '/by-store/store/page',
        method: 'post',
        data: req
    })
};

/**
 * 查询店铺
 */
export const queryStore = (req:any) => {
    return request({
        url: 'by-store/store/query',
        method: 'post',
        data: req
    })
}

/**
 * 详情 
 */
export const detailStore = (req:any) => {
    return request({
        url: '/by-store/store/detail',
        method: 'post',
        data: req
    })
}

/**
 * 查询所有状态
 */
export const getAllStatus = () => {
    return request({
        url: '/by-store/store/getAllStatus',
        method: 'post',
        data: null
    })
}

/**
 * 获取店铺类型
 */
export const getAllType = () => {
    return request({
        url: '/by-store/store/getAllType',
        method: 'post',
        data: null
    })
}

/**
 * 审批
 */
export const approveStore = (req:any) => {
    return request({
        url: '/by-store/store/approve',
        method: 'post',
        data: req
    })
}

/**
 * 获取店铺证书
 */
export const getCert = (req:any) => {
    return request({
        url: '/by-store/store/getCert',
        method: 'post',
        data: req
    })
}


/**
 * 分页查询商品信息
 */
export const pageGoods = (req:any) => {
    return request({
        url: '/by-store/goods/page',
        method: 'post',
        data: req
    })
}

/**
 * 查询所有的商品状态
 */
export const getAllGoodsStatus = () => {
    return request({
        url: '/by-store/goods/getAllStatus',
        method: 'post',
        data: null
    })
}

/**
 * 商品详情
 */
export const detailGoods = (req:any) => {
    return request({
        url: '/by-store/goods/detail',
        method: 'post',
        data: req
    })
}

/**
 * 审批商品
 */
export const approveGoods = (req:any) => {
    return request({
        url: '/by-store/goods/approve',
        method: 'post',
        data: req
    })
}

// ==========团购接口================

/**
 * 分页查询团购
 */
export const pageGroup = (req:any) => {
    return request({
        url: '/by-store/group/page',
        method: 'post',
        data: req
    })
}

/**
 * 查询所有团购状态
 */
export const getAllGroupStatus = () => {
    return request({
        url: '/by-store/group/getAllStatus',
        method: 'post',
        data: null
    })
}

/**
 * 团购详情
 */
export const detailGroup = (req:any) => {
    return request({
        url: '/by-store/group/detailMy',
        method: 'post',
        data: req
    })
}

/**
 * 审批通过团购
 */
export const passGroup = (req:any) => {
    return request({
        url: '/by-store/group/pass',
        method: 'post',
        data: req
    })
}

/**
 * 审批拒绝团购
 */
export const rejectGroup = (req:any) => {
    return request({
        url: 'by-store/group/reject',
        method: 'post',
        data: req
    })
}

// =============类目相关接口==================

/**
 * 获取所有业务类目
 */
export const getAllStoreBusinessType = () => {
    return request({
        url: 'by-store/business/category/getAllStoreBusinessType',
        method: 'post',
        data: null
    })
}

/**
 * 获取所有业务
 */
export const getAllStoreBusiness = (req:any) => {
    return request({
        url: '/by-store/business/category/getAllStoreBusiness',
        method: 'post',
        data: req
    })
}

/**
 * 新增业务类目
 */
export const addStoreBusinessCategory = (req:any) => {
    return request({
        url: '/by-store/business/category/add',
        method: 'post',
        data: req
    })
}

/**
 * 分页查询类目
 */
export const pageStoreBusinessCategory = (req:any) => {
    return request({
        url: '/by-store/business/category/page',
        method: 'post',
        data: req
    })
}

/**
 * 启用类目
 */
export const useStoreBusinessCategory = (req:any) => {
    return request({
        url: '/by-store/business/category/use',
        method: 'post',
        data: req
    })
}

/**
 * 停用类目
 */
export const stopStoreBusinessCategory = (req:any) => {
    return request({
        url: '/by-store/business/category/stop',
        method: 'post',
        data: req
    })
}