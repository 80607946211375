
import store from "@/store";
import { defineComponent, reactive, onMounted } from "vue";
import {
  detailStore,
  getCert,
  approveStore,
} from "../../../../../http/api/store";

interface DataStoreDetail {
  //店铺详情
  store: any;
  //证书列表
  certList: Array<any>;
  //审批dialog
  approveDialog: boolean;
  approveReq: {
    storeId: string;
    approveResult: boolean;
    reason: string;
  };
  //审批
  handleApprove: () => void;
  //取消审批
  closeApproveDialog: () => void;
  //提交审批结果
  submitApprove: () => void;
}

export default defineComponent({
  props: ["storeId"],
  setup(props, { emit }) {
    onMounted(() => {
      detailStore({ id: props.storeId }).then((res) => {
        if (res.data.data !== null) {
          data.store = res.data.data;
          //查询证书信息
          getCert({ id: props.storeId }).then((res) => {
            if (res.data.data !== null) {
              data.certList = res.data.data;
            }
          });
        }
      });
    });
    const data: DataStoreDetail = reactive({
      store: {},
      certList: [],
      //审批
      approveDialog: false,
      approveReq: {
        storeId: "",
        approveResult: true,
        reason: "",
      },
      //审批
      handleApprove() {
        data.approveDialog = true;
      },
      //取消审批
      closeApproveDialog() {
        data.approveDialog = false;
        //关闭
        data.approveReq = {
          storeId: "",
          approveResult: true,
          reason: "",
        };
      },
      //提交
      submitApprove() {
        let req = data.approveReq;
        req.storeId = data.store.id;
        approveStore(req).then((res) => {
          if (res.data !== null) {
            detailStore({ id: data.store.id }).then((result) => {
              if (result.data.data !== null) {
                data.store = result.data.data;
              }
              //关闭
              (data.approveDialog = false),
                (data.approveReq = {
                  storeId: "",
                  approveResult: true,
                  reason: "",
                });
            });
          }
        });
      },
    });
    return { data };
  },
});
