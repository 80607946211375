
import { defineComponent, reactive, onMounted } from "vue";
import { pageStore, getAllStatus, getAllType } from "../../../../../http/api/store";

interface DataStoreTable {
  //店铺列表
  storeList: Array<Object>;
  //状态列表
  statusList: Array<Object>;
  //类型列表
  typeList:Array<Object>;
  //数量
  count: number;
  //查询条件
  req: {
    status: any;
    type: any;
    pageSize: number;
    pageNum: number;
  };
  //前往详情
  handleDetail: (item: any) => void;
  //查询店铺信息
  selectStore: (val: number) => void;
  //搜索
  submit: () => void
}

export default defineComponent({
  emits: ["storeDetailId"],
  setup(props, { emit }) {
    onMounted(() => {
      let req = {
        pageSize: 10,
        pageNum: 1,
      };
      pageStore(req).then((res) => {
        if (res.data.data !== null) {
          data.storeList = res.data.data.list;
          data.count = Number(res.data.data.total);
        }
      });
      getAllStatus().then((res) => {
        if (res.data.data !== null) {
          data.statusList = res.data.data;
          data.statusList.unshift({
            status: -1,
            name: "全部",
          });
        }
      });
      //获取所有类型
      getAllType().then((res) => {
        if (res.data.data !== null) {
          data.typeList = res.data.data;
          data.typeList.unshift({
            type: "",
            typeName: "全部",
          });
        }
      })
    });
    const data: DataStoreTable = reactive({
      //店铺列表
      storeList: [],
      //状态列表
      statusList: [],
      //店铺类型列表
      typeList: [],
      //数量
      count: 0,
      //请求
      req: {
        status: null,
        type:null,
        pageSize:10,
        pageNum:1
      },
      //前往详情
      handleDetail(item: any) {
        emit("storeDetailId", item.id);
      },
      //分页查询
      selectStore(val: number){
        let req = data.req
        req.pageNum = val
        //赋值
        data.req = req
        pageStore(req).then((res) => {
          if (res.data.data !== null) {
            data.storeList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        })
      },
      //提价搜索
      submit(){
        let req = data.req
        req.pageNum = 1
        pageStore(req).then((res) => {
          if (res.data.data !== null) {
            data.storeList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        })
      }
    });
    return { data };
  },
});
