
import {
  defineComponent,
  reactive
} from "vue";
import MainCategory from "./main/category/main-category.vue";
import MainCharge from "./main/charge/main-charge.vue";
import MainContent from "./main/content/main-content.vue";
import MainData from "./main/data/main-data.vue";
import MainResource from "./main/resource/main-resource.vue";
import MainMarket from "./main/market/main-market.vue";
import MainSystem from "./main/system/main-system.vue";
import MainTag from "./main/tag/main-tag.vue";
import MainTemplate from "./main/template/main-template.vue";
import MainVideo from "./main/video/main-video.vue";
import MainCommunity from "./main/community/main-community.vue";
import MainStore from "./main/store/store/main-store.vue";
import MainGoods from "./main/store/goods/main-goods.vue";
import MainGroup from "./main/store/group/main-group.vue";
import MainStoreCategory from "./main/store/category/main-store-category.vue";

interface DataMain {}

export default defineComponent({
  //组件传参
  props: ["navIndex"],

  setup(props, ctx) {
    const data: DataMain = reactive({});
    return { data, props };
  },
  components: {
    MainData,
    MainCategory,
    MainTemplate,
    MainContent,
    MainVideo,
    MainTag,
    MainResource,
    MainMarket,
    MainSystem,
    MainCharge,
    MainCommunity,
    MainStore,
    MainGoods,
    MainGroup,
    MainStoreCategory
  },
});
