
import { defineComponent, reactive, onMounted } from "vue";
import {
  pageGroup,
  getAllGroupStatus,
  queryStore,
} from "../../../../../http/api/store";

interface DataGroupTable {
  req: {
    //分页参数
    pageSize: number;
    //分页参数
    pageNum: number;
    //商品名字
    name: any;
    //状态
    status: any;
    //店铺id
    storeIds: Array<string>;
  };
  //团购数量
  count: number;
  //团购列表
  groupList: Array<Object>;
  //全部状态
  statusList: Array<any>;
  //是否正在加载店铺
  loading: boolean;
  //店铺列表
  storeList: Array<StoreItem>;
  //搜索店铺
  searchStore: (key: any) => void;
  //前往详情
  handleDetail: (group: any) => void;
  //查询
  submit: () => void;
  //下一页
  selectGroup: (val: any) => void;
}

interface StoreItem {
  value: string;
  label: string;
}

export default defineComponent({
  emits: ["groupDetailId"],
  setup(props, { emit }) {
    onMounted(() => {
      let req = {
        pageNum: 1,
        pageSize: 10,
      };
      pageGroup(req).then((res) => {
        if (res.data.data !== null) {
          data.groupList = res.data.data.list;
          data.count = Number(res.data.data.total);
        }
      });
      //查询团购状态
      getAllGroupStatus().then((res) => {
        if (res.data.data !== null) {
          data.statusList = res.data.data;
          data.statusList.unshift({
            status: -1,
            statusName: "全部",
          });
        }
      });
    });

    const data: DataGroupTable = reactive({
      req: {
        //分页参数
        pageSize: 10,
        //分页参数
        pageNum: 1,
        //商品名字
        name: "",
        //状态
        status: null,
        //店铺id
        storeIds: [],
      },
      //数量
      count: 0,
      //团购列表
      groupList: [],
      //全部状态
      statusList: [],
      //是否正在加载店铺
      loading: false,
      //店铺列表
      storeList: [],
      //查询店铺
      searchStore(key: any) {
        data.loading = true;
        let req = {
          name: key,
        };
        if (key !== null && key !== "") {
          queryStore(req).then((res) => {
            if (res.data.data !== null) {
              let stores = res.data.data;
              let storeList = [];
              for (let index = 0; index < stores.length; index++) {
                let store = stores[index];
                let item = {
                  value: store.id,
                  label: store.name,
                };
                storeList.push(item);
              }
              data.storeList = storeList;
              data.loading = false;
            } else {
              data.storeList = [];
              data.loading = false;
            }
          });
        }
      },
      //前往详情
      handleDetail(group: any) {
        emit("groupDetailId", group.id);
      },
      //查询
      submit() {
        let req = data.req;
        req.pageNum = 1;
        pageGroup(req).then((res) => {
          if (res.data.data !== null) {
            data.groupList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        });
      },
      //查询
      selectGroup(val: any) {
        let req = data.req;
        req.pageNum = val;
        //赋值
        data.req = req
        pageGroup(req).then((res) => {
          if (res.data.data !== null) {
            data.groupList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        });
      },
    });
    return { data };
  },
});
