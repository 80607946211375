
import { defineComponent, reactive } from "vue";

interface DataNav {
  //跳转
  select: (i1: string, i2: string) => void;
}

export default defineComponent({
  props: {},
  setup(props, { emit }) {
    const data: DataNav = reactive({
      //跳转
      select(i1: string, i2: string) {
        if (i1 == "1") {
          if (i2 == "1") {
            emit("chooseNav", "1-1");
          }
        } else if (i1 == "2") {
          if (i2 == "1") {
            emit("chooseNav", "2-1");
          } else if (i2 == "2") {
            emit("chooseNav", "2-2");
          } else if (i2 == "3") {
            emit("chooseNav", "2-3");
          } else if (i2 == "4") {
            emit("chooseNav", "2-4");
          }
        } else if (i1 == "3") {
          if (i2 == "1") {
            emit("chooseNav", "3-1");
          } else if (i2 == "2") {
            emit("chooseNav", "3-2");
          }
        } else if (i1 == "4") {
          if (i2 == "1") {
            emit("chooseNav", "4-1");
          } else if (i2 == "2") {
            emit("chooseNav", "4-2");
          }
        } else if (i1 == "5") {
          if (i2 == "1") {
            emit("chooseNav", "5-1");
          } else if (i2 == "2") {
            emit("chooseNav", "5-2");
          } else if (i2 == "3") {
            emit("chooseNav", "5-3");
          } else if (i2 == "4") {
            emit("chooseNav", "5-4");
          }
        } else if (i1 == "6") {
          if (i2 == "1") {
            emit("chooseNav", "6-1");
          } else if (i2 == "2") {
            emit("chooseNav", "6-2");
          }
        } else if (i1 == "7") {
          if (i2 == "1") {
            emit("chooseNav", "7-1");
          } else if (i2 == "2") {
            emit("chooseNav", "7-2");
          } else if (i2 == "3") {
            emit("chooseNav", "7-3");
          } else if (i2 == "4") {
            emit("chooseNav", "7-4");
          }
        } else if (i1 == "8") {
          if (i2 == "1") {
            emit("chooseNav", "8-1");
          }
        }
      },
    });
    return { data };
  },
  emits: ["chooseNav"],
});
