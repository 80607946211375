
import { defineComponent, reactive } from "vue";
import GoodsTable from "./goods-table.vue";
import GoodsDetail from "./goods-detail.vue"

interface DataMainGoods {
  //页面类型
  pageType: string;
  //跳转的商品id
  goodsId: string;
  //店铺详情
  goodsDetailId:(goodsId:any) =>void;
  //前往主页
  toMain:() => void
}

export default defineComponent({
  components: { GoodsTable, GoodsDetail },
  setup(props, { emit }) {
    const data: DataMainGoods = reactive({
      //页面类型
      pageType: "list",
      //跳转的商品id
      goodsId: "",
      //店铺详情
      goodsDetailId(goodsId:any){
        data.pageType = 'detail'
        data.goodsId = goodsId
      },
      toMain(){
        data.pageType= 'list'
        data.goodsId = ''
      }
    });
    return { data };
  },
});
