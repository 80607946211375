
import { defineComponent, onMounted, reactive } from "vue";
import {
  detailCommunity,
  approveCommunity,
} from "../../../../http/api/content";

interface DataCommunityDetail {
  //社区详情
  community: any;
  //审核dialog
  approveDialog: boolean;
  //审批参数
  approveReq: {
    id: string;
    status: number;
    reason: string;
  };
  //审核
  handleApprove: () => void;
  //取消审批
  closeApproveDialog: () => void;
  //提交审批结果
  submitApprove: () => void;
}

export default defineComponent({
  props: ["communityId"],
  setup(props, { emit }) {
    onMounted(() => {
      let communityId = props.communityId;
      detailCommunity({ id: communityId }).then((res) => {
        if (res.data.data !== null) {
          data.community = res.data.data;
        }
      });
    });
    const data: DataCommunityDetail = reactive({
      //社区详情
      community: {},
      //审核dialog
      approveDialog: false,
      //审批req
      approveReq: {
        id: "",
        status: 3,
        reason: "",
      },
      //审核
      handleApprove() {
        data.approveDialog = true;
      },
      //取消审批
      closeApproveDialog() {
        (data.approveDialog = false),
          (data.approveReq = {
            id: "",
            status: 3,
            reason: "",
          });
      },
      submitApprove() {
        let req = data.approveReq;
        req.id = data.community.id;
        approveCommunity(req).then((res) => {
          if (res.data !== null) {
            data.approveDialog = false;
            data.approveReq = {
              id: "",
              status: 3,
              reason: "",
            };
            //重新查询数据
            detailCommunity({ id: data.community.id }).then((res) => {
              if (res.data.data !== null) {
                data.community = res.data.data;
              }
            });
          }
        });
      },
    });
    return { data };
  },
});
