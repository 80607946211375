
import { defineComponent, reactive, onMounted } from "vue";
import {
  pageGoods,
  getAllGoodsStatus,
  queryStore,
} from "../../../../../http/api/store";

interface DataGoodsTable {
  req: {
    //分页参数
    pageSize: number;
    //分页参数
    pageNum: number;
    //商品名字
    name: any;
    //状态
    status: any;
    //店铺id
    storeIds: Array<string>;
  };
  //数量
  count: number;
  //商品列表
  goodsList: Array<any>;
  //查询所有状态
  statusList: Array<any>;
  //是否正在加载店铺
  loading: boolean;
  //店铺列表
  storeList: Array<StoreItem>;
  //搜索店铺
  searchStore: (key: any) => void;
  //前往详情
  handleDetail: (goods: any) => void;
  //查询商品
  selectGoods: (val: any) => void;
  //查询
  submit: () => void;
}

interface StoreItem {
  value: string;
  label: string;
}
export default defineComponent({
  emits: ["goodsDetailId"],
  setup(props, { emit }) {
    onMounted(() => {
      //查询列表数据
      let req = {
        pageSize: 10,
        pageNum: 1,
      };
      pageGoods(req).then((res) => {
        if (res.data.data !== null) {
          data.goodsList = res.data.data.list;
          data.count = Number(res.data.data.total);
        }
      });
      getAllGoodsStatus().then((res) => {
        if (res.data.data !== null) {
          data.statusList = res.data.data;
          data.statusList.unshift({
            status: -1,
            statusName: "全部",
          });
        }
      });
    });
    const data: DataGoodsTable = reactive({
      //查询条件
      req: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        status: "",
        storeIds: [],
      },
      //数量
      count: 0,
      //商品列表
      goodsList: [],
      //所有商品状态
      statusList: [],
      //是否正在加载店铺
      loading: false,
      //店铺列表
      storeList: [],
      //查询店铺
      searchStore(key: any) {
        data.loading = true;
        let req = {
          name: key,
        };
        if (key !== null && key !== "") {
          queryStore(req).then((res) => {
            if (res.data.data !== null) {
              let stores = res.data.data;
              let storeList = [];
              for (let index = 0; index < stores.length; index++) {
                let store = stores[index];
                let item = {
                  value: store.id,
                  label: store.name,
                };
                storeList.push(item);
              }
              data.storeList = storeList;
              data.loading = false;
            } else {
              data.storeList = [];
              data.loading = false;
            }
          });
        }
      },
      //跳转详情
      handleDetail(goods: any) {
        emit("goodsDetailId", goods.id);
      },
      //查询商品
      selectGoods(val: any) {
        let req = data.req;
        req.pageNum = val;
        //赋值
        data.req = req
        pageGoods(req).then((res) => {
          if (res.data.data !== null) {
            data.goodsList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        });
      },
      //查询
      submit() {
        let req = data.req;
        req.pageNum = 1;
        pageGoods(req).then((res) => {
          if (res.data.data !== null) {
            data.goodsList = res.data.data.list;
            data.count = Number(res.data.data.total);
          }
        });
      },
    });
    return { data };
  },
});
