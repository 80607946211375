
import { defineComponent, onMounted, reactive } from "vue";
import { ChatLineSquare, Message, Warning } from "@element-plus/icons";
import { getMy } from "../../http/api/user";

interface DataHead {
  //用户信息
  userMsg: Object;
}
export default defineComponent({
  setup() {
    onMounted(() => {
      //加载用户
      getMy().then((res) => {
        if (res.data.data !== null) {
          data.userMsg = res.data.data;
        }
      });
    });
    const data: DataHead = reactive({
      //用户信息
      userMsg: {}
    });
    return { data };
  },
  components: { ChatLineSquare, Message, Warning },
});
