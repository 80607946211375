export const uploadUrl = {

    /**
     * 获取上传url
     */
    getUploadUrl(){
        let env = 'test'
        if (env == 'dev') {
            return "http://localhost:8000/api/by-resource/file/upload"
        }else if (env == 'test') {
            return "https://ysh.znfch.com/api/by-resource/file/upload"
        }else {
            return "https://ysh.znfch.com/api/by-resource/file/upload"
        }
    }
}